import React from 'react'
import moment from 'moment'
import { Link } from "gatsby"
import Layout from "../components/layout"
import Footer from "../components/footer"
import Header from "../components/header"
import TopHat from "../components/tophat"
import SEO from "../components/seo"
//import PropTypes from 'prop-types'

class DocumentPageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.agendas = props.data.graphAPI.district.agenda
    this.minutes = props.data.graphAPI.district.minutes
    this.documentsDate = props.data.graphAPI.district.documentsDate
    this.documentsAZ = props.data.graphAPI.district.documentsAZ
    
    this.agendasRange = this.getYearRange(this.agendas)
    this.minutesRange = this.getYearRange(this.minutes)

    this.state = {
      navSet: "Documents",
      sort: "Date",
      agendasYear: this.agendasRange.max,
      minutesYear: this.minutesRange.max
    }

    this.YearNav = this.YearNav.bind(this)
    this.setYear = this.setYear.bind(this)
    this.getCards = this.getCards.bind(this)
    this.setSort = this.setSort.bind(this)
  }

  setNav(val) {
    if(val) {
      this.setState({ navSet: val})
    }
  }

  setYear(year, type) {
    if(type === "Agendas") {
      this.setState({ agendasYear: year})
    } else if( type === "Minutes") {
      this.setState({ minutesYear: year})
    }
  }

  setSort(sortto) {
      this.setState({ sort: sortto})
  }
  getYearRange(array) {
    let min = new Date(array[0].meetingTime).getFullYear()
    let max = 0

    array.map( x => {
      let year = new Date(x.meetingTime).getFullYear()
      min = year < min ? year : min
      max = year > max ? year : max
    })

    return { min: min, max: max }
  }

  YearNav({set}) {
    let yearset = [ ];

    if(set === "Agendas") {
      for(let i = this.agendasRange.max; i >= this.agendasRange.min; i--) {
          yearset.push(i)
      }
    return(<div style={{marginBottom: '1rem'}}>
        <p>Select Year</p>
        <div className="buttons is-centered">
        {yearset.map(year => (
          <button 
            onClick={() => this.setYear(year, set)}
            className={'button '+( this.state.agendasYear == year ? 'is-primary' : '')}>
            {year}
          </button>
        ))}
        </div>
      </div>)
    }
    else if(set === "Minutes") {
      for(let i = this.minutesRange.max; i >= this.minutesRange.min; i--) {
          yearset.push(i)
      }
    return(<div style={{marginBottom: '1rem'}}>
        <p>Select Year</p>
        <div className="buttons is-centered">
        {yearset.map(year => (
          <button 
            onClick={() => this.setYear(year, set)}
            className={'button '+( this.state.minutesYear == year ? 'is-primary' : '')}>
            {year}
          </button>
        ))}
        </div>
      </div>)
    } else {
      return(
      <div style={{marginBottom: '1rem'}}>
        <p>Sort By</p>
          <button onClick={() => this.setSort('Date')} className={'button '+( this.state.sort == 'Date' ? 'is-primary' : '')}>Date Published</button>
          <button onClick={() => this.setSort('A-Z')} className={'button '+( this.state.sort == 'A-Z' ? 'is-primary' : '')}>A-Z</button>
      </div>
      )
    }
    
  }

  getCards() {
    if( this.state.navSet === "Agendas") {
      return (
        <>
        {this.agendas
          .filter(agenda => new Date(agenda.meetingTime).getFullYear() === this.state.agendasYear )
          .map(agenda =>(
          <div key={agenda.id} className="document-grid-item">
            <div className="card" style={{marginBottom: '1rem'}}>
              <div className="card-content">
                <p className="is-size-6">{agenda.meetingType} Meeting Agenda<br />
                <small className="has-text-grey">{moment(agenda.meetingTime).format("lll")}</small>
                </p>
              </div>
              <div className="card-footer">
                <a href={agenda.document.documentFile.publicURL} target="_blank" className="card-footer-item">View Agenda</a>
                <div className="card-footer-item">{agenda.document.documentFile.prettySize}</div>
              </div>
              { agenda.attachments.length > 0 ? (
                <>{agenda.attachments.map(attachment => (
                  <div key={attachment.id} className="card-footer">
                    <a href={attachment.Document.documentFile.publicURL} target="_blank" className="card-footer-item">{attachment.title}</a>
                    <div className="card-footer-item">{attachment.Document.documentFile.prettySize}</div>
                  </div>
                ))}</>
              ) : (<></>)}
            </div>
          </div>
        ))}
        </>
      )
    }else if( this.state.navSet === "Minutes") {
      return (
        <>
        {this.minutes
          .filter(minutes => new Date(minutes.meetingTime).getFullYear() === this.state.minutesYear )
          .map(minutes =>(
          <div key={minutes.id} className="document-grid-item">
            <div className="card" style={{marginBottom: '1rem'}}>
              <div className="card-content">
                <p className="is-size-6">{minutes.meetingType} Meeting Minutes<br />
                <small className="has-text-grey">{moment(minutes.meetingTime).format("ll")}</small>
                </p>
              </div>
              <div className="card-footer">
                <a href={minutes.document.documentFile.publicURL} target="_blank" className="card-footer-item">View Minutes</a>
                <div className="card-footer-item">{minutes.document.documentFile.prettySize}</div>
              </div>
              { minutes.attachments.length > 0 ? (
                <>{minutes.attachments.map(attachment => (
                  <div key={attachment.id} className="card-footer">
                    <a href={attachment.Document.documentFile.publicURL} target="_blank" className="card-footer-item">{attachment.title}</a>
                    <div className="card-footer-item">{attachment.Document.documentFile.prettySize}</div>
                  </div>
                ))}</>
              ) : (<></>)}
            </div>
          </div>
        ))}
        </>
      )

    } else {
      if (this.state.sort === 'Date') {
        return (
          <>
          { this.documentsDate.map(document =>(
            <div key={document.id} className="document-grid-item">
              <div className="card" style={{marginBottom: '1rem'}}>
                <div className="card-content">
                  <p className="is-size-6">{document.title}<br />
                  <small className="has-text-grey">{moment(document.publishedAt).format("ll")}</small>
                  </p>
                </div>
                <div className="card-footer">
                  <a href={document.document.documentFile.publicURL} target="_blank" className="card-footer-item">View Document</a>
                  <div className="card-footer-item">{document.document.documentFile.prettySize}</div>
                </div>
                { document.attachments.length > 0 ? (
                  <>{document.attachments.map(attachment => (
                    <div key={attachment.id} className="card-footer">
                      <a href={attachment.Document.documentFile.publicURL} target="_blank" className="card-footer-item">{attachment.title}</a>
                      <div className="card-footer-item">{attachment.Document.documentFile.prettySize}</div>
                    </div>
                  ))}</>
                ) : (<></>)}
              </div>
            </div>
          ))}
          </>
        )
      } else {
        return (
          <>
          { this.documentsAZ.map(document =>(
            <div key={document.id} className="document-grid-item">
              <div className="card" style={{marginBottom: '1rem'}}>
                <div className="card-content">
                  <p className="is-size-6">{document.title}<br />
                  <small className="has-text-grey">{moment(document.publishedAt).format("ll")}</small>
                  </p>
                </div>
                <div className="card-footer">
                  <a href={document.document.documentFile.publicURL} target="_blank" className="card-footer-item">View Document</a>
                  <div className="card-footer-item">{document.document.documentFile.prettySize}</div>
                </div>
                { document.attachments.length > 0 ? (
                  <>{document.attachments.map(attachment => (
                    <div key={attachment.id} className="card-footer">
                      <a href={attachment.Document.documentFile.publicURL} target="_blank" className="card-footer-item">{attachment.title}</a>
                      <div className="card-footer-item">{attachment.Document.documentFile.prettySize}</div>
                    </div>
                  ))}</>
                ) : (<></>)}
              </div>
            </div>
          ))}
          </>
        )
      }
    }
  }

  render() {



  return (

        <Layout>
            <SEO title="Documents" />
            <TopHat />
            <div className="main">
                <Header district={this.props.pageContext.district}  />
                <div className="main-content">
                    <div className="title-section">
                        <small style={{display: 'inline-block',paddingBottom: '1rem'}}>Hays WCID {this.props.pageContext.district}</small>
                        <h1 className="title is-size-1">Documents</h1>
                    </div>
                    <div className="content-align">
                        <h2  className="is-size-4">Meetings</h2>
                        { this.props.pageContext.district === '1' ? (
                          <>
                          <p>The District generally meets on the second Thursday of each month at 5:00 p.m. at the Belterra Center, 151 Trinity Hills Drive, Austin, TX 78737. The Board of Directors invites all members of the public to attend its meetings.</p>
                          <p>Agendas for District meetings are posted on this page approximately one week before the meeting. Please check this page for the agenda or <a href="mailto:info@touchstonedistrictservices.com?subject=hwcid%201%20agenda%20inquiry" target="_blank" rel="noopener noreferrer">email the District here</a> if you need confirmation of a Board meeting.</p>
                          </>
                        ) : (
                          <>
                          <p>The District generally meets on the third Thursday of each month at 5:45 p.m. at the Belterra Center, 151 Trinity Hills Drive, Austin, TX 78737. The Board of Directors invites all members of the public to attend its meetings.</p>
                          <p>Agendas for District meetings are posted on this page approximately one week before the meeting. Please check this page for the agenda or <a href="mailto:info@touchstonedistrictservices.com?subject=hwcid%202%20agenda%20inquiry" target="_blank" rel="noopener noreferrer">email the District here</a> if you need confirmation of a Board meeting.</p>
                          </>
                        )}
                    </div>

                    <div className="content-section" style={{ maxWidth: '100%'}}>

        <div className="tabs is-centered">
          <ul>
            <li onClick={ () => this.setNav("Agendas") }
              className={this.state.navSet === "Agendas" ? "is-active" : "" } >
              <a>Agendas</a>
            </li>
            <li onClick={ () => this.setNav("Minutes") }
              className={this.state.navSet === "Minutes" ? "is-active" : "" }> 
              <a>Minutes</a>
            </li>
            <li onClick={ () => this.setNav("Documents") }
              className={this.state.navSet === "Documents" ? "is-active" : "" }>
              <a>Documents</a>
            </li>
          </ul>
        </div>

        <div className="has-text-centered">
          <this.YearNav set={this.state.navSet} />
        </div>

        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="document-grid">
              <this.getCards />
            </div>
          </div>
        </div>
                    </div>
                </div>
                <Footer district={this.props.pageContext.district}></Footer>
            </div>
        </Layout>
  )
  }
} 


export default DocumentPageTemplate


export const documentPageQuery = graphql`
    query documentPageById($district: ID!) {
      graphAPI {
        district(id: $district) {
          agenda(sort: "meetingTime:desc") {
            meetingTime
            meetingType
            document {
              url
              updated_at
              documentFile {
                publicURL
                prettySize
              }
            }
            attachments {
              title
              id
              Document {
                url
                updated_at
                documentFile {
                  publicURL
                  prettySize
                }
              }
            }
          }
          documentsDate: documents(sort: "publishedAt:desc") {
            title
            publishedAt
            document {
              url
              updated_at
              documentFile {
                publicURL
                prettySize
              }
            }
            attachments {
              title
              id
              Document {
                url
                updated_at
                documentFile {
                  publicURL
                  prettySize
                }
              }
            }
          }
          documentsAZ: documents(sort: "title:asc") {
            title
            publishedAt
            document {
              url
              updated_at
              documentFile {
                publicURL
                prettySize
              }
            }
            attachments {
              title
              id
              Document {
                url
                updated_at
                documentFile {
                  publicURL
                  prettySize
                }
              }
            }
          }
          minutes(sort: "meetingTime:desc") {
            meetingTime
            meetingType
            document {
              url
              updated_at
              documentFile {
                publicURL
                prettySize
              }
            }
            attachments {
              title
              id
              Document {
                url
                updated_at
                documentFile {
                  publicURL
                  prettySize
                }
              }
            }
          }
        }
      }
    }
`